import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DetailLowongan from "../components/DetailLowongan"
import { fetchJobs, selectAppliedJobs } from "../redux/slices/jobs"

const DetailLowonganTemplate = ({ data }) => {
  const lowongan = data.contentfulJobOpening
  const dispatch = useDispatch()
  const { account } = useSelector((state) => ({
    account: state.session.account,
  }))

  const [job, setJob] = useState(null)

  const appliedJobs = useSelector(selectAppliedJobs)

  const getJob = (openingId) => {
    let newJob = null
    appliedJobs.map((job) => {
      if (job.openingId === openingId) {
        newJob = job
      }
    })
    return newJob
  }

  useEffect(() => {
    if (account) {
      dispatch(fetchJobs())
    }
  }, [account])

  useEffect(() => {
    if (account) {
      setJob(getJob(lowongan.backendId))
    }
  })

  if (lowongan) {
    return (
      <Layout>
        <SEO title={lowongan.nama} />
        {job ? (
          <div className="layout">
            <DetailLowongan
              lowongan={lowongan}
              type="appliedjob"
              account={account}
              backendJob={job}
            />
          </div>
        ) : (
          <div className="layout">
            <DetailLowongan
              lowongan={lowongan}
              type="jobfair"
              account={account}
            />
          </div>
        )}
      </Layout>
    )
  }
}

export default DetailLowonganTemplate

export const query = graphql`
  query JobOpeningData($slug: String!) {
    contentfulJobOpening(slug: { eq: $slug }) {
      nama
      lokasi
      slug
      backendId
      tags {
        tags
      }
      employmentType
      deskripsi {
        deskripsi
      }
      perusahaan {
        nama
        logo {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        lokasi
        kategori
        slug
        website
        nomorWhatsapp
        mediaSosial {
          internal {
            content
          }
        }
        deskripsi {
          deskripsi
        }
      }
      id
      jobCategory {
        kategori
      }
    }
  }
`
